// ====================================================
//
//    _alerts.scss
//
//    All bootstrap override styles for alerts, notes
//    and notification styles
//
// ====================================================

.alert {
    border-radius: 0;
    padding: 1rem;
    font-size: 0.85rem;
    line-height: 1.4;

    &.alert-success,
    &.alert-info,
    &.alert-warning,
    &.alert-danger,
    &.alert-error {

    }

    &.alert-success {

    }

    &.alert-info {

    }

    &.alert-warning {

    }

    &.alert-danger,
    &.alert-error {

    }

    &.alert-dismissible .close {
        right: -1rem;
        padding: 0.5rem 1rem
    }
}