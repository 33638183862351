// ====================================================
//
//      _tables.scss
//
//      Table styles and bootstrap overrides
//
// ====================================================

.table {
    box-sizing: border-box;

    thead {

        tr {
            background: #72ACD0;
            font-size: 0.8rem;
            text-transform: uppercase;
            font-weight: bold;
            color: white;
        }

        th {
            border-right: 1px dashed $color-grey-16;
            border-bottom: 3px double #72ACD0;
            white-space: nowrap;
            position: relative;

            &:last-child {
                border-right: none;
            }

            a {
                color: white;
                text-decoration: none;
                border-bottom: 1px dashed white;
            }

            &.sort::after {
                content: '\f0dc';
                float: right;
                font-family: 'FontAwesome';
                font-weight: 300;
            }

            &.sort.sort-asc,
            &.sort.sort-desc {
                background: #5893B7;
            }

            &.sort.sort-asc::after {
                content: '\f15d';
            }

            &.sort.sort-desc::after {
                content: '\f15e';
            }
        }
    }

    tbody {

        tr {
            &.active {
                background-color: #CAE8F7 !important;
            }
        }

        td {
            border-right: 1px dashed $color-grey-16;
            font-size: 0.8rem;
            padding: 1rem 0.75rem;
            white-space: nowrap;
            position: relative;

            &:last-child {
                border-right: none;
            }

            &.actions {
                text-align: center;

                button {
                    cursor: pointer;
                }

                .btn-link {
                    padding: 0 0.25rem;
                    color: #252525;
                }
            }
        }
    }

    td, th {

        &.center {
            text-align: center;
        }
    }

    &.table-bordered {

        th {
            border-top: 1px solid #72ACD0;

            &:first-child {
                border-left: 1px solid #72ACD0;
            }

            &:last-child {
                border-right: 1px solid #72ACD0;
            }
        }
    }

    &.table-striped {
        tbody {
            tr:nth-of-type(odd) {
                background-color: transparent;
            }
            tr:nth-of-type(even) {
                background-color: $color-grey-18;
            }
        }
    }

    &.table-sm {
        td, th {
            padding: 0.75rem 0.75rem;
        }
    }

    &.table-hover {
        tbody {
            tr {
                @include transition();

                &:hover {
                    background: lighten($color-primary, 48%);
                }
            }

        }
    }

    &.va-middle {
        td {
            vertical-align: middle;
        }
    }
}

@media screen and (min-width: $screen-sm-min)
{
    .table {
        thead {

            th {
                white-space: normal;
            }
        }

        tbody {

            td {
                white-space: normal;
            }
        }
    }
}

.grid-view-header {
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    .table-count {
        width: auto;
        padding: 0.75rem 0;
        color: #6b6b6b;
    }

    .per-page {
        flex-grow: 1;
        text-align: right;
        padding: 0;

        label {
            color: #6b6b6b;
            font-weight: bold;
            margin-right: 0.5rem;
        }

        select.custom-select {
            width: 60px;
            padding: 0.4rem 1.5rem 0.4rem 0.5rem;
            height: auto;
        }
    }

    .action-buttons {

        .btn {
            padding: 0.4rem 0.8rem;
        }
    }
}

.no-results {
    font-size: 0.8rem;
    padding: 1.5rem;
    background: #efefef;
}


.undefined {
    color: $color-grey-13;
    font-style: italic;
}