/* ====================================================

    mixins.scss

    A variety of common SASS mixins

==================================================== */

// CSS 3 Transitions
@mixin transition($duration: 0.3s, $easing: ease, $properties: all) {
    -webkit-transition: $duration $easing $properties;
    -moz-transition: $duration $easing $properties;
    transition: $duration $easing $properties;
}

// Clear Default Appearance;
@mixin clearAppearance() {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: transparent;
}