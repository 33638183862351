// Form Field Styles
input {
    font-family: 'Open Sans';
}

// Bootstrap Overrides
.form-group {

    label {
        font-size: 0.7rem;
        padding: 0.5rem 0;
        text-transform: uppercase;
    }

    &.required label::after {
        content: '*';
        color: #AD0000;
        margin-left: 0.2rem;
    }

    .form-control {
        border-radius: 0;
        font-size: 0.8rem;
        padding: 0.75rem;
    }

    textarea.form-control {
        min-height: 120px;
        resize: none;
    }

    .form-text:not(:empty) {
        font-size: 0.8rem;
        padding: 0.5rem 0;
        line-height: 1.5;
    }

    // Select Fields
    .custom-select {
        border-radius: 0;
        display: block;
        width: 100%;
        height: auto;
    }

    // Checkboxes & Radios
    .custom-control {
        padding-left: 1.5rem;
        text-transform: none;
        font-size: 0.8rem;

        .custom-control-indicator {
            top: 0.4rem;
        }

        input[type="checkbox"] + .custom-control-indicator {
            border-radius: 0.15rem;
        }

        .custom-control-input:checked ~ .custom-control-indicator {
            background-color: $color-primary;
        }

        .custom-control-input:focus~.custom-control-indicator {
            -webkit-box-shadow: 0 0 0 1px #fff, 0 0 0 2px $color-primary;
            box-shadow: 0 0 0 1px #fff, 0 0 0 2px $color-primary;
        }
    }

    .character-count {
        font-size: 0.7rem;
        padding: 0.5rem 0rem;
        color: #4a4a4a;

        &.over {
            color: red;
        }
    }
}

// Floating Labels

$floating-label-input-padding-x: 1rem;
$floating-label-input-padding-y: 1rem;

$floating-label-input-active-padding-top:    1.5rem;
$floating-label-input-active-padding-bottom: 0.5rem;

$floating-label-input-font-size: 0.8rem;

.form-group {

    .floating-label {
        position: relative;
        height: calc((#{$floating-label-input-font-size} * 1.25) + (#{$floating-label-input-padding-y} * 2) + 2px);

        label {
            position: absolute;
            left: $floating-label-input-padding-x;
            top: 50%;
            transform: translateY(-50%);
            font-size: $floating-label-input-font-size;
            color: grey;
            padding: 0;
            text-transform: none;
            pointer-events: none;

            @include transition();
        }

        textarea + label {
            padding: 0.5rem 0.5rem 0.25rem 0;
            background: white;
        }

        input, textarea {
            position: absolute;
            top: 0;
            left: 0;
            background: transparent;
            padding: $floating-label-input-padding-y $floating-label-input-padding-x;
            font-size: $floating-label-input-font-size;
        }

        // Floating label style
        input:focus + label,
        textarea:focus + label,
        &.filled label,
        input[value]:not([value=""]) + label {
            font-size: 0.6rem;
            text-transform: uppercase;
            top: 0.9rem;
        }

        // Focused label colour
        input:focus + label,
        textarea:focus + label {
            color: $color-primary;
        }

        input:focus,
        textarea:focus,
        &.filled input,
        &.filled textarea,
        input[value]:not([value=""]) {
            padding-top: $floating-label-input-active-padding-top;
            padding-bottom: $floating-label-input-active-padding-bottom;
        }
    }

    &.has-danger {
        label {
            color: $color-error;
        }
    }

    &.has-warning {
        label {
            color: $color-warning;
        }
    }

    &.has-success {
        label {
            //color: $color-success;
        }
    }

    .form-control-feedback {
        font-size: 0.8rem;
        padding-top: 0.5rem;
        font-weight: bold;
    }

    .custom-select {
        font-size: 0.8rem;
        padding: 0.75rem;
    }

    input[type="color"] {
        padding: 0.25em;
        height: 3em;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
        padding: 0.25em;
    }
    input[type="color"]::-webkit-color-swatch {
        border: none;
    }
}

// Buttons
.btn {
    font-size: 0.8rem;
    border-radius: 0.1rem;
    font-weight: 300;

    &.btn-sm {
        padding: .25rem .5rem;
        font-size: 0.7rem;
    }

    &.btn-lg {
        font-size: 0.9rem;
    }
}

.btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;

    &:hover {
        background-color: darken($color-primary, 8%);
        border-color: darken($color-primary, 8%);
    }

    &:active {
        color: #fff;
        background-color: darken($color-primary, 8%);
        border-color: darken($color-primary, 8%);
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.btn-secondary {

    &:active {
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.btn-success {
    background-color: $color-success;
    border-color: $color-success;

    &:hover {
        background-color: darken($color-success, 8%);
        border-color: darken($color-success, 8%);
    }

    &:active {
        color: #fff;
        background-color: darken($color-success, 8%);
        border-color: darken($color-success, 8%);
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.btn-info {
    background-color: $color-info;
    border-color: $color-info;

    &:hover {
        background-color: darken($color-info, 8%);
        border-color: darken($color-info, 8%);
    }

    &:active {
        color: #fff;
        background-color: darken($color-info, 8%);
        border-color: darken($color-info, 8%);
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.btn-warning {
    background-color: $color-warning;
    border-color: $color-warning;

    &:hover {
        background-color: darken($color-warning, 8%);
        border-color: darken($color-warning, 8%);
    }

    &:active {
        color: #fff;
        background-color: darken($color-warning, 8%);
        border-color: darken($color-warning, 8%);
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

.btn-danger, .btn-error {
    background-color: $color-danger;
    border-color: $color-danger;

    &:hover {
        background-color: darken($color-danger, 8%);
        border-color: darken($color-danger, 8%);
    }

    &:active {
        color: #fff;
        background-color: darken($color-danger, 8%);
        border-color: darken($color-danger, 8%);
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
    }
}

// Pagination
$pagination-color: #3e8ebf;
.pagination {
    margin-top: 0rem;
    justify-content: flex-end;

    li {

        a, span {
            display: inline-block;
            width: 2rem;
            height: 2rem;
            text-align: center;
            line-height: 2rem;
            margin: 0 0.125rem;

            @include transition();
        }

        a {
            color: $pagination-color;
            border: 1px solid $pagination-color;
            background: transparent;
        }

        span {
            color: $color-grey-14;
            border: 1px solid $color-grey-14;
            background: transparent;
        }

        &.active > span,
        a:hover {
            background: $pagination-color;
            color: white;
            border: 1px solid transparent;
            text-decoration: none;
        }

        a:active, a:visited, a:focus {
            text-decoration: none;
        }

        &:first-child {
            a, span {
                margin-left: 0;
            }
        }

        &:last-child {
            a, span {
                margin-right: 0;
            }
        }
    }
}

// Multi Select Overrides
.ms-container {
    width: 100%;

    .ms-selectable {
        margin-bottom: 30px;
    }

    .ms-selectable,
    .ms-selection {
        width: 100%;

        li.ms-elem-selectable,
        li.ms-elem-selection {
            padding: 0.75rem;
        }

        .custom-header {
            padding: 0.5rem 0.25rem;
            font-size: 0.8rem;
            background: #f1f1f1;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-bottom: none;
            text-align: center;
            text-transform: uppercase;
        }
    }
    .ms-list {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,.15);
    }

    .ms-optgroup {
        li.ms-elem-selectable,
        li.ms-elem-selection {
            padding-left: 1.5rem;
        }
    }

    .ms-optgroup-label {
        padding: 0.5rem 0.75rem;
        background: #f1f1f1;
        font-size: 0.7rem;
        text-transform: uppercase;
    }
}

@media screen and (min-width: $screen-sm-min)
{
    .ms-container {
        width: 100%;

        .ms-selectable {
            margin: 0;
        }

        .ms-selectable,
        .ms-selection {
            width: calc(50% - 15px);
        }
    }
}

.badge {
    font-size: 0.7rem;
    vertical-align: middle;
}

.list-group {
    font-size: 1rem;

    &.with-icons .list-group-item {
        padding: 0 0 0 1.25rem;
    }

    .list-group-item {
        border-radius: 0;
        padding: 1em 1.25em;
        font-size: 0.9em;
        color: #656565;

        .action-buttons {
            font-size: 0;
            a {
                font-size: 0.9rem;
                display: inline-block;
                padding: 1em;
                border-left: 1px solid rgba(0, 0, 0, 0.125);
                color: #656565;
                @include transition();

                &:hover {
                    background: rgba(0, 0, 0, 0.05);
                    color: #141A2B;
                }
            }
        }
    }

    .list-group-item:first-child {
        border-radius: 0;
    }
    .list-group-item:last-child {
        border-radius: 0;
    }
}