// Store Contacts
.store-contact {
    margin-right: 0;
    margin-left: 0;
    box-shadow: 0 2px 5px 0 rgba(0 ,0, 0, 0.05);
    margin-bottom: 1rem;

    .contact-name {
        padding: 1rem;
        border: 1px solid #e4e4e4;
        vertical-align: middle;
        font-size: 1rem;
        line-height: 1.3rem;
        background: #eef7f9;

        .action-buttons {
            float: right;
        }
    }

    .contact-phone,
    .contact-mobile,
    .contact-email {
        border: 1px solid #e4e4e4;
        border-top: 0px;
        padding: 1rem;
        font-size: 0.8rem;

        .icon {
            display: block;
            text-transform: uppercase;
            font-size: 0.7rem;
            color: grey;
            margin-bottom: 0.5rem;

            i {
                font-size: 1.2rem;
                margin-right: 0.4rem;
                vertical-align: middle;
            }
        }
    }

    .contact-mobile {
        border-right: none;
        border-left: none;
    }
}