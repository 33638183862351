// ====================================================
//
//    _colours.scss
//
//    Holds all colour styles, including background
//    colours, text colours and border colours
//
// ====================================================

.color-red-lighter  { background: $color-red-lighter; }
.color-red-light    { background: $color-red-light; }
.color-red          { background: $color-red; }
.color-red-dark     { background: $color-red-dark; }
.color-red-darker   { background: $color-red-darker; }

.color-orange-lighter  { background: $color-orange-lighter; }
.color-orange-light    { background: $color-orange-light; }
.color-orange          { background: $color-orange; }
.color-orange-dark     { background: $color-orange-dark; }
.color-orange-darker   { background: $color-orange-darker; }

.color-yellow-lighter  { background: $color-yellow-lighter; }
.color-yellow-light    { background: $color-yellow-light; }
.color-yellow          { background: $color-yellow; }
.color-yellow-dark     { background: $color-yellow-dark; }
.color-yellow-darker   { background: $color-yellow-darker; }

.color-green-lighter  { background: $color-green-lighter; }
.color-green-light    { background: $color-green-light; }
.color-green          { background: $color-green; }
.color-green-dark     { background: $color-green-dark; }
.color-green-darker   { background: $color-green-darker; }

.color-blue-lighter  { background: $color-blue-lighter; }
.color-blue-light    { background: $color-blue-light; }
.color-blue          { background: $color-blue; }
.color-blue-dark     { background: $color-blue-dark; }
.color-blue-darker   { background: $color-blue-darker; }

.color-purple-lighter  { background: $color-purple-lighter; }
.color-purple-light    { background: $color-purple-light; }
.color-purple          { background: $color-purple; }
.color-purple-dark     { background: $color-purple-dark; }
.color-purple-darker   { background: $color-purple-darker; }

.color-grey-1   { background: $color-grey-1; }
.color-grey-2   { background: $color-grey-2; }
.color-grey-3   { background: $color-grey-3; }
.color-grey-4   { background: $color-grey-4; }
.color-grey-5   { background: $color-grey-5; }
.color-grey-6   { background: $color-grey-6; }
.color-grey-7   { background: $color-grey-7; }
.color-grey-8   { background: $color-grey-8; }
.color-grey-9   { background: $color-grey-9; }
.color-grey-10  { background: $color-grey-10; }
.color-grey-11  { background: $color-grey-11; }
.color-grey-12  { background: $color-grey-12; }
.color-grey-13  { background: $color-grey-13; }
.color-grey-14  { background: $color-grey-14; }
.color-grey-15  { background: $color-grey-15; }
.color-grey-16  { background: $color-grey-16; }
.color-grey-17  { background: $color-grey-17; }
.color-grey-18  { background: $color-grey-18; }

.color-white { background: white; }
.color-black { background: black; }

// Text Colours
.text-red-lighter  { color: $color-red-lighter; }
.text-red-light    { color: $color-red-light; }
.text-red          { color: $color-red; }
.text-red-dark     { color: $color-red-dark; }
.text-red-darker   { color: $color-red-darker; }

.text-orange-lighter  { color: $color-orange-lighter; }
.text-orange-light    { color: $color-orange-light; }
.text-orange          { color: $color-orange; }
.text-orange-dark     { color: $color-orange-dark; }
.text-orange-darker   { color: $color-orange-darker; }

.text-yellow-lighter  { color: $color-yellow-lighter; }
.text-yellow-light    { color: $color-yellow-light; }
.text-yellow          { color: $color-yellow; }
.text-yellow-dark     { color: $color-yellow-dark; }
.text-yellow-darker   { color: $color-yellow-darker; }

.text-green-lighter  { color: $color-green-lighter; }
.text-green-light    { color: $color-green-light; }
.text-green          { color: $color-green; }
.text-green-dark     { color: $color-green-dark; }
.text-green-darker   { color: $color-green-darker; }

.text-blue-lighter  { color: $color-blue-lighter; }
.text-blue-light    { color: $color-blue-light; }
.text-blue          { color: $color-blue; }
.text-blue-dark     { color: $color-blue-dark; }
.text-blue-darker   { color: $color-blue-darker; }

.text-purple-lighter  { color: $color-purple-lighter; }
.text-purple-light    { color: $color-purple-light; }
.text-purple          { color: $color-purple; }
.text-purple-dark     { color: $color-purple-dark; }
.text-purple-darker   { color: $color-purple-darker; }

.text-grey-1   { color: $color-grey-1; }
.text-grey-2   { color: $color-grey-2; }
.text-grey-3   { color: $color-grey-3; }
.text-grey-4   { color: $color-grey-4; }
.text-grey-5   { color: $color-grey-5; }
.text-grey-6   { color: $color-grey-6; }
.text-grey-7   { color: $color-grey-7; }
.text-grey-8   { color: $color-grey-8; }
.text-grey-9   { color: $color-grey-9; }
.text-grey-10  { color: $color-grey-10; }
.text-grey-11  { color: $color-grey-11; }
.text-grey-12  { color: $color-grey-12; }
.text-grey-13  { color: $color-grey-13; }
.text-grey-14  { color: $color-grey-14; }
.text-grey-15  { color: $color-grey-15; }
.text-grey-16  { color: $color-grey-16; }
.text-grey-17  { color: $color-grey-17; }
.text-grey-18  { color: $color-grey-18; }

.text-white { color: white; }
.text-black { color: black; }
