// ====================================================
//
//      _panels.scss
//
//      Panel related styles and variations
//
// ====================================================

.panel {
    background: white;
    font-size: 1rem;
    margin-bottom: 1rem;

    .panel-header {
        padding: 1em 1.5rem;
        border-bottom: 1px solid #E6E6E6;

        .title {
            font-size: 0.75em;
            text-transform: uppercase;
            color: #2097d2;
            color: #afafaf;
        }
    }

    .panel-content {
        padding: 1.5rem;
        font-size: 0.9em;
    }

    &.form {
        .panel-content {
            padding: 2.5rem;
        }
    }
}

.panel-tabs {
    ul {
        font-size: 0;

        li {
            display: inline;
            font-size: 1rem;

            a {
                display: inline-block;
                background: #F7F7F7;
                font-size: 0.75em;
                text-transform: uppercase;
                color: #afafaf;
                padding: 1rem 1.5rem;
                border-right: 1px solid #E6E6E6;

                &:focus {
                    text-decoration: none;
                }
            }

            &:last-child a {
                border-right: none;
            }

            &.active a {
                background: white;
                border-bottom: 1px solid white;
                font-weight: 600;
            }
        }
    }
}

.panel.tabbed {
    .panel-content {
        display: none;

        &.active {
            display: block;
        }
    }
}