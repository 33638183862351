// ====================================================
//
//    _layout.scss
//
//    Holds all styles related to the general core
//    layout, i.e. the navigation, content areas,
//    headers etc and core page aspects
//
// ====================================================

// Auth Layout
.auth-wrapper {
    display: grid;
    grid-template-columns: 100%;
    width: 100%;
    height: 100vh;

    .background {
        display: none;
    }

    .form-container {
        background: #141A2B;
        position: relative;

        .auth-form {
            padding: 2rem;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);

            h2 {
                font-size: 1.2rem;
                font-weight: normal;
            }

            .floating-label {
                width: 100%;
            }

            input {
                background: #2a334a !important;
                color: white;
            }

            label {
                //color: rgba(255, 255, 255, 0.5) !important;
            }

            .custom-control {
                .custom-control-input:focus~.custom-control-indicator {
                    -webkit-box-shadow: 0 0 0 1px #141A2B, 0 0 0 2px $color-primary;
                    box-shadow: 0 0 0 1px #141A2B, 0 0 0 2px $color-primary;
                }
            }

            .btn-link:hover {
                color: white;
            }

            .help-block {
                padding: 0.5rem;
                display: inline-block;
                font-size: 0.8rem;
                color: #ff8888;
            }
        }
    }
}

.no-cssgrid .auth-wrapper {

    .form-container {
        height: 100vh;
    }

    .auth-form {
        //position: relative;
        //top: 0;
        //transform: none;
    }
}

@media screen and (min-width: $screen-sm-min)
{
    .auth-wrapper {
        display: grid;
        grid-template-columns: auto 500px;
        width: 100%;
        height: 100vh;

        .background {
            display: block;
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;

            background-image: url('/images/auth-background.jpg');
            background-size: cover;
            position: relative;

            .action-result {
                padding: 4rem 2rem;
                position: absolute;
                top: 50%;
                width: 60%;
                transform: translateX(-50%)translateY(-50%);
                left: 50%;

                .alert {
                    margin: 0;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
                }
            }
        }

        .form-container {
            position: relative;
            height: auto;
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;

            box-shadow: -10px 0px 25px rgba(0, 0, 0, 0.5);

            .auth-form {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    .no-cssgrid .auth-wrapper {
        display: block;

        .background {
            display: block;
            width: calc(100% - 500px);
            height: 100vh;
            float: left;
        }

        .form-container {
            width: 500px;
            float: left;
        }
    }
}

// Wrapper
.page-wrap {
    display: grid;
    grid-template-rows: $header-height auto auto;
    grid-template-columns: 100%;
    height: 100vh;
    overflow: hidden;
}

@media screen and (min-width: $screen-sm-min)
{
    .page-wrap {
        grid-template-rows: $header-height auto;
        grid-template-columns: $sidebar-xs-width auto;
        grid-template-columns: $sidebar-sm-width auto;
    }
}

@media screen and (min-width: $screen-md-min)
{
    .page-wrap {
        grid-template-rows: $header-height auto;
        grid-template-columns: $sidebar-xs-width auto;
        grid-template-columns: $sidebar-md-width auto;
    }
}

@media screen and (min-width: $screen-lg-min)
{
    .page-wrap {
        grid-template-rows: $header-height auto;
        grid-template-columns: $sidebar-xs-width auto;
        grid-template-columns: $sidebar-lg-width auto;
    }
}

// Grid Layout
.main-header {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
}

.main-sidebar {
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
}

.main-content {
    grid-row: 3 / span 1;
    grid-column: 1 / span 1;
    min-height: calc(100vh - #{$header-height});
}

.no-cssgrid {

    .page-wrap {
        display: block;
        width: 100%;
    }

    .main-header {
        display: block;
        height: 60px;
        width: 100%;
        float: left;
    }

    .main-sidebar {
        width: 100%;
        height: auto;
        float: left;
    }

    .main-content {
        width: 100%;
        margin-top: 0px;
        height: 100vh;
    }
}

@media screen and (min-width: $screen-xs-max)
{
    .main-header {
        grid-row: 1 / span 1;
        grid-column: 2 / span 1;
    }

    .main-sidebar {
        grid-row: 1 / span 2;
        grid-column: 1 / span 1;
    }

    .main-content {
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
        min-height: auto;
    }

    .no-cssgrid {
        display: block;

        .main-header {
            display: block;
            height: 60px;
            width: calc(100% - 225px);
            float: right;
        }

        .main-sidebar {
            width: 225px;
            height: 100vh;
            float: left;
        }

        .main-content {
            width: calc(100% - 225px);
            margin-top: 60px;
            height: 100vh;
        }
    }
}


// Header
.main-header {
    position: relative;
    background: $header-background;
    height: 100%;
    border-bottom: 1px solid $main-content-background;

    &::after {
        content: '';
        display: block;
        background: $color-primary;
        height: 2px;
        width: 0%;
        position: absolute;
        opacity: 0;
    }

    &.loading::after {
        width: 75%;
        opacity: 1;
        @include transition(3s, cubic-bezier(0.075, 0.82, 0.165, 1), width)
    }

    &.loaded::after {
        width: 100%;
        opacity: 1;
        @include transition(1s, cubic-bezier(0.075, 0.82, 0.165, 1), all)
    }
}

.header-wrapper {
    display: flex;
    height: 100%;

    .search-form, .icon-tools, .profile-dropdown {
        display: none;
    }

    .mobile-header {
        flex-grow: 1;
        text-align: right;

        .mobile-menu-handle {
            position: relative;
            display: inline-block;
            height: $header-height;
            width: $header-height;
            text-align: center;
            font-size: 1rem;
            color: grey;

            @include transition();

            &:hover {
                color: $color-primary;
            }

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
}

@media screen and (min-width: $screen-sm-min)
{
    // Internal Header Wrapper
    .header-wrapper {

        .search-form, .icon-tools, .profile-dropdown {
            display: block;
        }

        .mobile-header {
            display: none;
        }

        .search-form {
            width: $header-search-width;
            color: grey;

            form {
                position: relative;
                height: 100%;

                input {
                    height: 100%;
                    width: 100%;
                    padding-left: 4rem;
                    padding-right: 1rem;
                    font-size: 0.9em;
                    @include clearAppearance();

                    &:focus {
                        outline: none;
                    }
                }

                // Set up the underline for the search box
                .underline {
                    display: block;
                    position: absolute;
                    background: $color-primary-light;
                    left: 0;
                    bottom: 0;
                    content: '';
                    height: 2px;
                    width: 0%;

                    @include transition();
                }
                input:focus + .underline {
                    width: 100%;
                }

                i {
                    position: absolute;
                    top: 50%;
                    left: 1.5rem;
                    transform: translateY(-50%);
                }

                input:not(:empty) + label {
                    display: none;
                }
            }
        }

        .icon-tools {
            flex-grow: 1;
            text-align: right;
            font-size: 0;

            .header-dropdown {
                display: inline-block;
                position: relative;

                .header-dropdown-content {
                    display: none;
                    text-align: left;
                    position: absolute;
                    min-width: 250px;
                    top: 100%;
                    left: 0;
                    background: white;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                    z-index: 1000;

                    &.right {
                        right: 0;
                        left: auto;
                    }
                }
            }

            .tool-handle {
                position: relative;
                display: inline-block;
                height: $header-height;
                width: $header-height;
                text-align: center;
                font-size: 1rem;
                color: grey;
                border-left: 1px solid #E2E2E2;

                @include transition();

                &:hover {
                    color: $color-primary;
                }

                i, span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                }

                span {
                    font-size: 1rem;
                    font-weight: bold;
                }
            }
        }

        .brand-switcher-dropdown {
            max-height: 400px;
            overflow: auto;

            ul {

                li {
                    border-bottom: 1px solid #F1F1F1;
                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        position: relative;
                        padding: 1.25rem 2.5rem 1.25rem 3rem;
                        display: block;
                        @include transition();
                    }

                    .brand-switcher-icon {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        border: 1px solid #b7b7b7;
                        border-radius: 50%;
                        position: absolute;
                        top: 50%;
                        left: 1rem;
                        transform: translateY(-50%);
                    }

                    .brand-name {
                        font-size: 0.8rem;
                        line-height: 1.2rem;
                        color: #494949;
                        @include transition();
                    }

                    .brand-code {
                        position: absolute;
                        top: 50%;
                        right: 1rem;
                        transform: translateY(-50%);
                        font-size: 0.7rem;
                        line-height: 1rem;
                        color: #a7a7a7;
                    }

                    a:active, a:focus {
                        text-decoration: none;
                    }

                    a:hover {
                        text-decoration: none;

                        .brand-switcher-icon {
                            background: $color-primary;
                            box-shadow: inset 0 0 0 2px white;
                        }

                        .brand-name {
                            color: $color-primary;
                        }
                    }

                    &.active {
                        background: #EAF8FF;

                        .brand-switcher-icon {
                            background: $color-primary;
                            box-shadow: inset 0 0 0 2px white;
                        }
                    }
                }
            }
        }

        .profile-dropdown {
            width: $header-height;
            border-left: 1px solid $main-content-background;
            position: relative;

            .user-dropdown-handle {
                display: block;
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
                width: 40px;
                height: 40px;
                padding: 10px;

                img {
                    border-radius: 50%;

                }
            }

            .user-options-dropdown {
                display: none;
                position: absolute;
                right: 0;
                top: 100%;
                background: white;
                width: 250px;
                border-top: 1px solid #e2e2e2;
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
                z-index: 1000;

                ul {
                    margin: 0.5rem 0;

                    li {
                        a {
                            display: block;
                            font-size: 0.9rem;
                            padding: 1rem 2rem;
                            color: #888888;
                            text-decoration: none;

                            @include transition();

                            &:hover {
                                color: #298CBD;
                                background: rgba(0, 0, 0, 0.05);
                            }
                        }

                        span {
                            display: block;
                            font-size: 0.9rem;
                            padding: 1rem 2rem;
                            color: $color-primary;
                        }

                        &.divider {
                            height: 1px;
                            background: whitesmoke;
                            margin: 0.5rem 0;
                        }
                    }
                }
            }
        }
    }
}

// Sidebar - Nav
.main-sidebar {
    display: none;
    background: $sidebar-background;

    .header-logo {
        display: none;
    }

    .main-navigation {
        font-size: 1rem;

        // First Level List
        ul {

            li {

                a {
                    position: relative;
                    display: block;
                    color: #9195A1;
                    padding: 0.75rem 1rem;
                    font-size: 0.8em;
                    text-decoration: none;

                    @include transition();

                    i {
                        width: 1.5rem;
                        height: 1.5rem;
                        font-size: 1.2rem;
                        text-align: center;
                        line-height: 1.5rem;
                        margin-right: 1rem;
                    }
                }

                // Hover and selected item styles
                &:hover > a, &.selected > a {
                    background: rgba(255, 255, 255, 0.05);
                    color: white;
                }

                // Parent item styling
                &.parent > a::after {
                    font-family: 'FontAwesome';
                    content: '\f078';
                    position: absolute;
                    top: 50%;
                    right: 1.5rem;
                    transform-origin: center center;
                    transform: translateY(-50%);
                    font-size: 0.75em;

                    @include transition(0.3s, ease, transform);
                }

                // Selected parent, rotate icon
                &.parent.selected > a::after {
                    transform: translateY(-50%) rotate(180deg);
                }

                // Second Level List
                ul {
                    margin: 0.5rem 0;

                    li {

                        a {
                            font-size: 0.7em;
                            padding: 0.75rem 3rem;
                        }
                    }
                }
            }
        }
    }

    .mobile-nav-container {
        font-size: 1rem;

        form {
            position: relative;
            width: 100%;
            margin: 1rem 0 0.5rem 0;

            i {
                position: absolute;
                top: 50%;
                left: 1.5em;
                transform: translateY(-50%);
                color: #9195A1;
            }

            input {
                @include clearAppearance();
                width: 100%;
                font-size: 0.8em;
                padding: 0.75rem 2rem 0.75rem 4rem;
                border-top: 1px solid #2e364a;
                border-bottom: 1px solid #2e364a;
                color: #9195A1;
            }
        }

        ul {
            margin-bottom: 1rem;

            li {

                a {
                    position: relative;
                    display: block;
                    color: #9195A1;
                    padding: 0.75rem 1rem 0.75rem 2rem;
                    font-size: 0.8em;
                    text-decoration: none;

                    @include transition();

                    i {
                        width: 1.5rem;
                        height: 1.5rem;
                        font-size: 1.2rem;
                        text-align: center;
                        line-height: 1.5rem;
                        margin-right: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: $screen-xs-max)
{
    .main-sidebar {
        display: block;
        //padding-top: $header-height;

        .header-logo {
            display: block;
            height: $header-height;
            position: relative;
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                max-width: 80%;
            }
        }

        .mobile-nav-container {
            display: none;
        }
    }
}

// Content
.main-content {
    background: $main-content-background;
    max-height: calc(100vh - #{$header-height});
    overflow: auto;

    .page-header {
        display: block;
        width: 100%;
        padding: 1.5rem 2rem 0 2rem;
        text-align: center;

        h2 {
            font-size: 1.5rem;
            color: #3777A7;
            margin-bottom: 0;
            margin-top: 0;
        }

        .page-details {
            flex-grow: 1;
            width: 100%;
        }

        .actions {
            text-align: center;
            width: 100%;
        }

        .breadcrumbs {
            font-size: 1rem;

            li {
                display: inline;
                position: relative;

                &:not(:last-child)::after {
                    content: '>';
                    font-size: 0.5rem;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                }

                a {
                    font-size: 0.75em;
                    text-transform: uppercase;
                    color: grey;
                    padding: 1em 0;
                    display: inline-block;
                    margin: 0 0.75em;
                    text-decoration: none;

                    @include transition();

                    &:hover {
                        color: $color-primary;
                    }
                }

                &:first-child > a {
                    margin-left: 0;
                }
            }
        }
    }

    .inner-content {
        padding: 1rem;
    }

    .content-footer {
        text-align: center;
        font-size: 0.75rem;
        color: #ababab;
        padding: 0.5rem 0;
    }
}

@media screen and (min-width: $screen-sm-min)
{
    .main-content {

        .page-header {
            display: flex;
            text-align: left;

            .page-details {
                width: auto;
                flex-grow: 1;
            }

            .actions {
                width: auto;
                flex-grow: 1;
                text-align: right;
            }

            .breadcrumbs {

            }
        }
    }
}