// ====================================================
//
//    styles.scss
//
//    Core stylesheet for the site. Includes all
//    necessary files required for the site
//
// ====================================================

// Fonts
//@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800");

// Reset
@import "reset";

// Variables & Mixins
@import "variables";
@import "mixins";

// Layout
@import "layout";

// Style Modules
@import "typography";
@import "form";
@import "panels";
@import "colours";
@import "alerts";
@import "tables";

// Core App Styles
@import "app";

html, body {
    font-size: 100%;
    font-family: 'Open Sans';
    color: #1E1E1E;
}

strong {
    font-weight: bold;
}

#page-wrap:-webkit-full-screen {
    width: 100%;
    height: 100%;
}

#page-wrap:-moz-full-screen {
    width: 100%;
    height: 100%;
}

#page-wrap:-ms-fullscreen {
    width: 100%;
    height: 100%;
}

#page-wrap:fullscreen {
    width: 100%;
    height: 100%;
}

// Badge Lists
.toggle-badge-list {
    display: none;
    text-transform: uppercase;
    font-size: 0.7rem;
    margin-left: 0.5rem;
    color: #12a1ce;
    text-decoration: underline;
}

.badge-list {
    display: none;
    line-height: 1.5;
    margin-top: 0.5rem;
}

.table-info-button {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 0.85rem;
    color: white;
    background: #65c6ec;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    text-align: center;
    line-height: 1.25rem !important;
    border-radius: 50%;
    font-size: 0.7rem;
}