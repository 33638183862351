// ====================================================
//
//    _variables.scss
//
//    Holds all variables used within the site
//
// ====================================================

// Bootstrap Breakpoints
// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-xs-min:              $screen-xs !default;
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

// Layout
$header-height: 60px;
$sidebar-xs-width: 200px;
$sidebar-sm-width: 200px;
$sidebar-md-width: 200px;
$sidebar-lg-width: 225px;

// Header
$header-background: white;

$header-search-width: 15%;

// Sidebar
$sidebar-background: #141A2B;

// Main Content
$main-content-background: #E2E2E2;

// Colours
$color-red-lighter:         #FDDDDD;
$color-red-light:           #F77975;
$color-red:                 #F55753;
$color-red-dark:            #CD4945;
$color-red-darker:          #933432;

$color-orange-lighter:      #FFE8BE;
$color-orange-light:        #FFC253;
$color-orange:              #FFAA0E;
$color-orange-dark:         #D08703;
$color-orange-darker:       #8E5C00;

$color-yellow-lighter:      #FFFAD1;
$color-yellow-light:        #FFEF72;
$color-yellow:              #FFE412;
$color-yellow-dark:         #E2D200;
$color-yellow-darker:       #C7B100;

$color-green-lighter:       #C8FFDF;
$color-green-light:         #8FF3B8;
$color-green:               #44DE83;
$color-green-dark:          #1FBF61;
$color-green-darker:        #048A3B;

$color-blue-lighter:        #DAEFFD;
$color-blue-light:          #6DC0F9;
$color-blue:                #48B0F7;
$color-blue-dark:           #3C93CE;
$color-blue-darker:         #2B6A94;

$color-purple-lighter:      #C3BAE6;
$color-purple-light:        #9384CC;
$color-purple:              #6D5CAE;
$color-purple-dark:         #503F94;
$color-purple-darker:       #3A2D6F;

// Core

$color-primary-light: #A4D6F5;
$color-primary: #2097D2;

$color-success: #5CB85C;
$color-info:    #5bc0de;
$color-warning: #f0ad4e;
$color-danger:  #d9534f;
$color-error:   #d9534f;

$color-grey-1:                    hsl(0, 0%, 10%);
$color-grey-2:                    hsl(0, 0%, 15%);
$color-grey-3:                    hsl(0, 0%, 20%);
$color-grey-4:                    hsl(0, 0%, 25%);
$color-grey-5:                    hsl(0, 0%, 30%);
$color-grey-6:                    hsl(0, 0%, 35%);
$color-grey-7:                    hsl(0, 0%, 40%);
$color-grey-8:                    hsl(0, 0%, 45%);
$color-grey-9:                    hsl(0, 0%, 50%);
$color-grey-10:                   hsl(0, 0%, 55%);
$color-grey-11:                   hsl(0, 0%, 60%);
$color-grey-12:                   hsl(0, 0%, 65%);
$color-grey-13:                   hsl(0, 0%, 70%);
$color-grey-14:                   hsl(0, 0%, 75%);
$color-grey-15:                   hsl(0, 0%, 80%);
$color-grey-16:                   hsl(0, 0%, 85%);
$color-grey-17:                   hsl(0, 0%, 90%);
$color-grey-18:                   hsl(0, 0%, 95%);

// Margin Classes

.m-t-0  { margin-top: 0px; }
.m-t-5  { margin-top: 5px; }
.m-t-10 { margin-top: 10px; }
.m-t-15 { margin-top: 15px; }
.m-t-20 { margin-top: 20px; }
.m-t-25 { margin-top: 25px; }
.m-t-30 { margin-top: 30px; }
.m-t-35 { margin-top: 35px; }
.m-t-40 { margin-top: 40px; }
.m-t-45 { margin-top: 45px; }
.m-t-50 { margin-top: 50px; }

.m-t-1em { margin-top: 1em; }
.m-t-2em { margin-top: 2em; }
.m-t-3em { margin-top: 3em; }
.m-t-4em { margin-top: 4em; }
.m-t-5em { margin-top: 5em; }

.m-t-1rem { margin-top: 1rem; }
.m-t-2rem { margin-top: 2rem; }
.m-t-3rem { margin-top: 3rem; }
.m-t-4rem { margin-top: 4rem; }
.m-t-5rem { margin-top: 5rem; }

.m-b-0  { margin-bottom: 0px; }
.m-b-5  { margin-bottom: 5px; }
.m-b-10 { margin-bottom: 10px; }
.m-b-15 { margin-bottom: 15px; }
.m-b-20 { margin-bottom: 20px; }
.m-b-25 { margin-bottom: 25px; }
.m-b-30 { margin-bottom: 30px; }
.m-b-35 { margin-bottom: 35px; }
.m-b-40 { margin-bottom: 40px; }
.m-b-45 { margin-bottom: 45px; }
.m-b-50 { margin-bottom: 50px; }

.m-b-1em { margin-bottom: 1em; }
.m-b-2em { margin-bottom: 2em; }
.m-b-3em { margin-bottom: 3em; }
.m-b-4em { margin-bottom: 4em; }
.m-b-5em { margin-bottom: 5em; }

.m-b-1rem { margin-bottom: 1rem; }
.m-b-2rem { margin-bottom: 2rem; }
.m-b-3rem { margin-bottom: 3rem; }
.m-b-4rem { margin-bottom: 4rem; }
.m-b-5rem { margin-bottom: 5rem; }

.m-r-0  { margin-right: 0px; }
.m-r-5  { margin-right: 5px; }
.m-r-10 { margin-right: 10px; }
.m-r-15 { margin-right: 15px; }
.m-r-20 { margin-right: 20px; }
.m-r-25 { margin-right: 25px; }
.m-r-30 { margin-right: 30px; }
.m-r-35 { margin-right: 35px; }
.m-r-40 { margin-right: 40px; }
.m-r-45 { margin-right: 45px; }
.m-r-50 { margin-right: 50px; }

.m-r-1em { margin-right: 1em; }
.m-r-2em { margin-right: 2em; }
.m-r-3em { margin-right: 3em; }
.m-r-4em { margin-right: 4em; }
.m-r-5em { margin-right: 5em; }

.m-r-1rem { margin-right: 1rem; }
.m-r-2rem { margin-right: 2rem; }
.m-r-3rem { margin-right: 3rem; }
.m-r-4rem { margin-right: 4rem; }
.m-r-5rem { margin-right: 5rem; }

.m-l-0  { margin-left: 0px; }
.m-l-5  { margin-left: 5px; }
.m-l-10 { margin-left: 10px; }
.m-l-15 { margin-left: 15px; }
.m-l-20 { margin-left: 20px; }
.m-l-25 { margin-left: 25px; }
.m-l-30 { margin-left: 30px; }
.m-l-35 { margin-left: 35px; }
.m-l-40 { margin-left: 40px; }
.m-l-45 { margin-left: 45px; }
.m-l-50 { margin-left: 50px; }

.m-l-1em { margin-left: 1em; }
.m-l-2em { margin-left: 2em; }
.m-l-3em { margin-left: 3em; }
.m-l-4em { margin-left: 4em; }
.m-l-5em { margin-left: 5em; }

.m-l-1rem { margin-left: 1rem; }
.m-l-2rem { margin-left: 2rem; }
.m-l-3rem { margin-left: 3rem; }
.m-l-4rem { margin-left: 4rem; }
.m-l-5rem { margin-left: 5rem; }