// ====================================================
//
//      _typography.scss
//
//      All typographic styles
//
// ====================================================

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

// Headings

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
    margin-top: 0.25rem;
    color: #464646;

    &.bordered {
        border-bottom: 1px solid gainsboro;
        padding-bottom: 0.5em;
    }
}

h1 {
    font-size: 2.074rem;
}

h2 {
    font-size: 1.728rem;
}

h3 {
    font-size: 1.44rem;
}

h4 {
    font-size: 1.2rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.833rem;
    text-transform: uppercase;
}

// Paragraphs

p {
    line-height: 1.5;
    margin-bottom: 1rem;

    &.lead {
        font-size: 1.2rem;
    }
}

// Text Styles

.uppercase {
    text-transform: uppercase;
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

// Lists

ul.list, ol.list {
    margin-bottom: 1rem;

    li {
        list-style-position: inside;
        padding: 0.375rem 0 ;

        ul, ol {
            margin: 0.75rem 0 0 1rem;
        }

        ul {
            li {
                list-style-type: disc;
            }
        }

        ol {
            li {
                list-style-type: lower-alpha;
            }
        }
    }
}

ul.list {
    li {
        list-style-type: circle;
    }
}

ol.list {
    li {
        list-style-type: decimal;
    }
}

dl.list {

    dt {
        padding: 0.5rem 0;
        font-weight: bold;
    }

    dd {
        font-style: italic;
        margin-bottom: 0.5rem;
        line-height: 1.5;
    }
}

// Quotes

blockquote {
    font-size: 1.2rem;
    padding: 1rem;
    background: #f7f7f7;
    line-height: 1.5;
    color: #4c4c4c;
    border-left: 0.5rem solid #dadada;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;

    footer {
        font-size: 1rem;
        font-style: italic;
        padding: 0.5rem 0 0 0;
    }
}

// Others

address {
    background: #f7f7f7;
    padding: 1rem;
    line-height: 1.5;
}

.badge {
    //display: inline-block;
    //font-size: 75%;
    //font-weight: 700;
    //line-height: 1;
    //color: rgb(255, 255, 255);
    //text-align: center;
    //white-space: nowrap;
    //vertical-align: baseline;
    //padding: 0.25em 0.4em;
    //border-radius: 0.25rem;

    font-size: 80%;
    font-weight: 500;
    padding: 0.4em 0.6em;
    margin: 0 0 0.25em 0;
}

.text-muted {
    color: #ABB0B3 !important;
    font-style: italic;
}

hr.form-controls-separator {
    margin-bottom: 1rem;
}

$placeholder-color: #b7b7b7;
.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholder-color;
    font-style: italic;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
    color: $placeholder-color;
    font-style: italic;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
    color: $placeholder-color;
    font-style: italic;
}
.form-control:-moz-placeholder { /* Firefox 18- */
    color: $placeholder-color;
    font-style: italic;
}